

import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import saveAs from "save-as";
import stores from "@/store/store";
const store: any = stores;
@Component
export default class PreQualificationComponent extends Vue {
  public prequalDocUrl = null;

  public async preQualificationLetter() {
    try {
      this.$store.state.wemloLoader = true;
      let body = {
        loanTxnId: this.$route.query.loanTxnId,
        userId: this.$userId,
        brokerId: this.$brokerId
      };

      let response = await Axios.post(BASE_API_URL + "loan/preQualificationLetter", body);

      this.prequalDocUrl = response.data.letter;
      if (this.prequalDocUrl != null) this.$store.state.wemloLoader = false
    } catch (error) {
      console.log(error);
    }
  }
  public runToPricing() {
    this.$router.push({
      path: this.$getCurrentUserTypePath("ppe-results"),
      query: { loanTxnId: this.$route.query.loanTxnId }
    });
  }
  public async saveUrl() {
    window.localStorage.setItem(
      "prequalDocUrl",
      JSON.stringify(this.prequalDocUrl)
    );
  }
  public async downloadPreQual() { // Not removing since new one tested & approved
    try {
      this.$store.state.wemloLoader = true;
      let dataObj: any = {
        check: true,
        data: [this.prequalDocUrl],
        name: "PreQualificationLetter" + this.$route.query.loanTxnId
      };

      let response = await Axios.post(
        BASE_API_URL + "common/downloadFileS3",
        dataObj,
        { responseType: "blob" }
      );
      saveAs(
        response.data,
        "Prequalificationletter" + this.$route.query.loanTxnId + ".zip"
      );
      if (response.status == 200) this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.preQualificationLetter();
    this.saveUrl();
  }
}
